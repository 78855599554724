<template>
  <ConfirmDialog
    :value.sync="show"
    maxWidth="1220px"
    title="Rejestr zmian"
    positiveButtonText=""
    :loading="loading"
    noPadding
    :compact="false"
    negativeButtonColor="primary"
    showCloseBtn
  >
    <v-layout column align-center mb-2 slot="header" style="width: 100%">
      <DefaultLabel>{{ name }}</DefaultLabel>
    </v-layout>

    <v-layout column slot="content">
      <v-layout class="popup-content" column>
        <v-layout column>
          <v-layout class="mx-2">
            <RoundArrowButton @clicked="previous" left></RoundArrowButton>
            <v-layout column justify-center grow>
              <div class="zg-hfdr-line"></div>
            </v-layout>
            <DrawingHistoryMenu
              :selected.sync="drawing"
              :delegates="drawings"
            ></DrawingHistoryMenu>
            <v-layout column justify-center grow>
              <div class="zg-hfdr-line"></div>
            </v-layout>
            <RoundArrowButton @clicked="next" right></RoundArrowButton>
          </v-layout>
          <v-layout v-if="drawing" align-center class="mx-4 my-2">
            <DefaultLabel class="mr-2">Autor:</DefaultLabel>
            <DefaultSubtitle color="primary">{{
              drawing.edited_by
            }}</DefaultSubtitle>
            <v-spacer></v-spacer>
            <DefaultLabel>{{ drawing.modified }}</DefaultLabel>
          </v-layout>
          <SubviewCard class="mx-4 mb-4" noActions>
            <v-img v-if="currentDrawing" :src="currentDrawing"></v-img>
          </SubviewCard>
        </v-layout>
        <v-flex
          v-if="records != undefined && records.length == 0 && !loading"
          class="text-center"
        >
          <div class="text-subtitle-2 error--text">Brak wpisów</div>
        </v-flex>
      </v-layout>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
import CRUDMixin from "@/mixins/CRUDMixin";
import PatientService from "@/services/patient.service";
import DrawingsService from "@/services/drawings.service";

export default {
  mixins: [CRUDMixin],
  props: {
    value: {},
    record: {},
    session: {},
    patient: {},
    owner: {},
    name: {
      type: String,
      default: "Ilustracja",
    },
  },
  data() {
    return {
      currentIndex: 0,
      currentDrawing: null,
      drawings: undefined,
      totalItems: 0,
      pagination: {
        rowsPerPage: 5,
        page: 1,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    drawing: {
      get() {
        if (
          this.drawings &&
          this.currentIndex >= 0 &&
          this.currentIndex <= this.drawings.length
        ) {
          return this.drawings[this.currentIndex];
        } else {
          return {};
        }
      },
      set(value) {
        const index = this.drawings.indexOf(value);
        if (index !== -1) {
          this.currentIndex = index;
        }
      },
    },
  },
  watch: {
    currentIndex() {
      if (this.drawings.length > this.currentIndex) {
        this.downloadPicture(this.drawings[this.currentIndex].file).then(
          (picture) => (this.currentDrawing = picture)
        );
      }
    },
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    RoundArrowButton: () => import("@/components/buttons/RoundArrowButton"),
    SubviewCard: () => import("@/components/cards/SubviewCard"),
    DrawingHistoryMenu: () => import("@/components/menus/DrawingHistoryMenu"),
  },
  methods: {
    fetchData() {
      this.beforeRequest();
      PatientService.getHistoryField({
        name: "Drawings",
        session: this.session.id,
        options: this.pagination,
        field: 2,
        type: this.record.id,
        patient: this.patient ? this.patient.id : undefined,
        owner: this.owner ? this.owner.id : undefined,
      })
        .then((x) => {
          this.drawings = x;
          this.reset();
          this.downloadPicture(this.drawings[0].file).then(
            (picture) => (this.currentDrawing = picture)
          );
        })
        .catch((x) => this.handleError(x));
    },
    downloadPicture(url) {
      let inst = this;
      return new Promise((resolve, reject) => {
        inst.beforeRequest();
        DrawingsService.downloadPicture(
          process.env.VUE_APP_URL_BACKEND + "/media/" + url,
          this.owner ? this.owner.id : undefined
        )
          .then((reply) => {
            const blob = new Blob([reply], {
              type: "image/jpeg",
            });
            inst.reset();
            resolve(URL.createObjectURL(blob));
          })
          .catch((error) => {
            inst.reset();
            reject(error);
          });
      });
    },
    next() {
      if (this.currentIndex + 1 < this.drawings.length) {
        this.currentIndex = this.currentIndex + 1;
      } else {
        this.currentIndex = 0;
      }
    },
    previous() {
      if (this.currentIndex - 1 >= 0) {
        this.currentIndex = this.currentIndex - 1;
      } else {
        this.currentIndex = this.drawings.length - 1;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped lang="css">
.popup-content {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.zg-hfdr-line {
  background-color: var(--v-primary-base);
  height: 2px;
  width: 100%;
}
</style>